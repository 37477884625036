@import "../../_mixins.scss";

.inspirational-quote {
    display: block;
    font-family: "Merriweather", serif;
    font-size: 1.6rem;
    line-height: 1.9rem;
    margin: 0;
    padding: 0 0 0 1rem;
    text-align: right;
    // Hide quote section on small and medium screens
    @include respond-to(small) {
        display: none;
    }
    @include respond-to(medium) {
        display: none;
    }
    @include respond-to(large) {
        display: none;
    }
    figcaption {
        font-size: 1.2rem;
        font-family: "Mulish", sans-serif;
        font-weight: normal;
        @include respond-to(medium) {
            font-size: 1rem;
        }
    }
}
