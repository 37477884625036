.animated-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-image: linear-gradient(0deg, rgba(#323232, 0.5) 1px, transparent 1px),
        linear-gradient(90deg, rgba(#323232, 0.5) 1px, transparent 1px);
    background-size: 50px 50px;
}
