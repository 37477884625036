@import "./mixins";

.contact-page {
    background-color: #ffffffbe;
    border-radius: 0.25rem;
    width: 60%;
    margin: 0 auto;
    margin-bottom: 2rem;
    padding: 2rem 2rem;
    @include respond-to(small) {
        width: auto;
        padding: 1rem 1rem;
        margin: 1rem;
    }
    h2 {
        font-size: 2.2rem;
        margin-bottom: 1rem;
    }
}
