@import "./mixins";

#root {
    height: 100%;
}

/* Headings */
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Merriweather", serif;
    color: #323232;
}

/* Body Copy */
body {
    font-family: "Mulish", sans-serif;
    line-height: 1;
    background-color: #f2f0ef;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #323232;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

a {
    text-decoration: none;
    color: unset;
    font-size: 1rem;
}

h1,
h2,
h3,
h4,
h5 {
    font-weight: bold;
    margin: 3px;
    padding: 3px;
}

h1 {
    font-size: 2.7rem;
}

h2 {
    font-size: 2.2rem;
}

h3 {
    font-size: 1.7rem;
}

h4 {
    font-size: 1.25rem;
}

code {
    font-family: "Cabin", sans-serif;
}

hr {
    border: 0;
    height: 1px;
    background: #32323215;
}
