@import "./mixins";

.container {
    width: 90%;
    max-width: 1800px;
    margin: 0 auto;
    @include respond-to(small) {
        width: 95%;
    }
}
