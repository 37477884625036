@import "../../mixins";

.header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    @include respond-to(small) {
        grid-template-columns: 1fr 1fr;
        padding: 0;
        margin-bottom: 0.5rem;
    }
    &__social {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 0 5rem;
        &__item {
            margin-left: 1rem;
            a {
                img {
                    width: 2rem;
                }
            }
        }
        @include respond-to(small) {
            display: none;
        }
        @include respond-to(medium) {
            padding: 0;
        }
    }
    &__contact {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    a.contact-button {
        background-color: #323232;
        width: 8rem;
        font-weight: 700;
        text-transform: capitalize;
    }
    hr {
        margin-bottom: 1rem;
        border: 0;
        border-top: 1px solid rgba(#f2f0ef, 1);
        border-radius: 0.25rem;
    }
}
