@import "../../mixins";

.contact-form {
    label {
        line-height: 1.6rem;
        font-weight: 700;
    }
    input,
    textarea {
        background-color: #ffffffc8;
        padding: 1.2rem 0.9rem;
    }
    &__message {
        padding: 1.2rem 0.9rem;
        border: 1px solid rgba(0, 0, 0, 0.23);
        border-radius: 0.25rem;
        outline: none;
        resize: vertical;
        font-family: inherit;
        font-size: 1.2rem;
        line-height: 1.1876em;
        &.error {
            border-color: #f44336;
        }
    }
    &__submit-button {
        margin-top: 1rem;
        background-color: #323232;
        width: 10rem;
        text-transform: capitalize;
        @include respond-to(small) {
            width: 100%;
        }
    }
}
